import Speech from 'speak-tts'
/**
 * 文字转语音
 */
let speech = null
speech = new Speech()
speech.setLanguage('zh-CN')
speech.init({
    volume: 1, // 音量
    lang: 'zh-CN', // 语言
    rate: 1, // 语速
    pitch: 1, // 音调
    voice:'Microsoft Yaoyao - Chinese (Simplified, PRC)',
    splitSentences: true, // 在句子结束时暂停
}).then(()=>{})
export const speakPlay = (text) => {
    for(let i=0;i<3;i++) {
        speech.speak({text:text}).then(()=>{})
    }
}
export const speakStop = () => {
    speech.cancel()
}
