<template>
    <div>
        <div class="alarm-wrap" :class="alarmPopupFlag?'alarm-popup-show':'alarm-popup-hide'" :style="{background:conBackground}">
            <div class="alarm-title" :style="{background:titleBackground}">
                <div>
                    <span><i class="iconfont icon-gaojingguanli"></i></span>
                    <span class="title-text">{{ title }}</span>
                </div>
                <div>
                    <span class="alarm-sty alarm-sty1" :style="{background:tipBackground}"></span>
                    <span class="alarm-sty alarm-sty2" :style="{background:tipBackground}"></span>
                    <span class="alarm-sty alarm-sty3" :style="{background:tipBackground}"></span>
                </div>
            </div>
            <div class="alarm-content">
                <div class="con-top">
                    <div>
                        <div class="title">{{ subTitle }}</div>
                        <div>{{alarmData.title}}</div>
                    </div>
                    <!-- <div class="con-tag">{{alarmData.tag}}</div> -->
                </div>
                <div class="con-bottom">
                    <div class="title">{{ content }}</div>
                    <div>{{alarmData.content}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            alarmData: this.alarmDatas
        }
    },
    props: {
        alarmPopupFlag: {
            type: Boolean
        },
        alarmDatas: {
            type: Object
        },
        titleBackground: {
            type: String,
            default: '#ff4757'
        },
        conBackground: {
            type: String,
            default: '#ffe6e9'
        },
        tipBackground: {
            type: String,
            default: 'rgb(255, 104, 116)' 
        },
        title: {
            type: String,
            default: '告警提示' 
        },
        subTitle: {
            type: String,
            default: '告警名称' 
        },
        content: {
            type: String,
            default: '告警内容' 
        }
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.alarm-wrap {
    width: 300px;
    font-size: 14px;
    border-radius: 8px;
    background: #ffe6e9;
    position: absolute;
    overflow: hidden;
    top: 0%;
    left: 50%;
    z-index: 11000;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translate(-50%,-112px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .alarm-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ff4757;
        color: #FFF;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        user-select: none;
        .title-text {
            margin-left: 5px;
        }
    }
    .alarm-content {
        padding: 10px;
        .con-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .con-tag {
                background: #ffcdd1;
                border-radius: 4px;
                color: #ff4757;
                height: 30px;
                line-height: 30px;
                padding: 0 10px;
            }
        }
    }
    .title {
        color: #7a7a7a;
        font-size: 12px;
        padding-bottom: 3px;
        user-select: none;
    }
    .content {
        color: #282828;
    }
    .alarm-sty {
        width: 4px;
        height: 18px;
        background: rgb(255, 104, 116);
        display: inline-block;
        margin: 10px 3px 0 3px;
        transform: rotate(30deg);
        border-radius: 2px;
    }
}
.alarm-popup-show {
    transform: translate(-50%,120px);
    opacity: 1;
}

.alarm-popup-hide {
    transform: translate(-50%,-112px);
    opacity: 0;
}
</style>